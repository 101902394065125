document.addEventListener("turbolinks:load", () => {
  update_errors_navbar_tip();
});

function update_errors_navbar_tip() {
  $('.nav-tabs li').each(function(i, item) {
    if ($(item).find('a').attr('href').startsWith('#form-')) {
      let tab = $(item).find('a').attr('href');
      if ($(tab).find('.invalid-feedback').length > 0) {
        $(".nav-tabs li:nth-child(" + (i + 1) + ") a").append(
          ' <span data-toggle="tooltip" title="" class="badge bg-red" data-original-title="' +
          $(tab).find('.invalid-feedback').length +
          ' erro' +
          ($(tab).find('.invalid-feedback').length > 1 ? 's' : '') +
          ' nesta aba">' +
          $(tab).find('.invalid-feedback').length +
          '</span>');
      }
    }
  });
}
