// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../images', true)

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'bootstrap';

import 'admin-lte';

import "@nathanvda/cocoon";

import 'select2/dist/js/select2.full';

import 'jquery-mask-plugin';
import 'inputmask/dist/jquery.inputmask.js';

import 'bootstrap-fileinput';
import 'bootstrap-fileinput/js/locales/pt-BR';
import 'bootstrap-fileinput/themes/fas/theme';

import "selectize";

import "@fortawesome/fontawesome-free/css/all.css";

import '../stylesheets/application';

import './configuracoes_esocial'
import './enderecos'
import './pagamentos'
import './pessoas'
import './remuneracoes_devidas'
import './rubricas'
import './vinculos'

$(document).on('turbolinks:before-cache', function() {
  // this approach corrects the select 2 to be duplicated when clicking the back button.
  $("select.select2-hidden-accessible").select2('destroy');
});

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  // Initialize select2
  add_select2();
  addSelect2Cidades();
  addSelect2Cbos();
  addSelect2Pessoas();

  // Initialize custom file input
  add_file_input();

  // Initialize selectize with add (without modal)
  add_selectize_without_modal();

  // Initialize masks
  add_masks();
  add_money_masks();

  $('.checkable').prop('checked', $('#checkbox-toggle').checked);

  $("#checkbox-toggle").click(function(){
    $('.checkable').prop('checked', this.checked);
  });
});

window.add_select2 = function(element) {
  var sel_element
  if (element) {
    sel_element = element;
  } else {
    sel_element = $(".select2")
  }
  $(sel_element).select2({
    allowClear: true,
    width: '100%',
    language: 'pt-BR',
    placeholder: "Selecione..."
  });
}

window.addSelect2Cidades = function() {
    $(".select2_cidades").select2({
        allowClear: true,
        width: '100%',
        language: "pt-BR",
        placeholder: "Buscar Cidade",
        minimumInputLength: 3,
        ajax: {
            dataType: 'json',
            url: "/utils/cidades",
            processResults: function(data) {
                return {
                    results: data
                };
            }
        }
    });
}


window.addSelect2Cbos = function() {
    $(".select2_cbos").select2({
        allowClear: true,
        width: '100%',
        language: "pt-BR",
        placeholder: "Buscar CBO",
        minimumInputLength: 3,
        ajax: {
            dataType: 'json',
            url: "/utils/cbos",
            processResults: function(data) {
                return {
                    results: data
                };
            }
        }
    });
}

window.addSelect2Pessoas = function () {
  $(".select2_pessoas").select2({
      allowClear: true,
      width: '100%',
      language: "pt-BR",
      placeholder: "Buscar pessoa",
      minimumInputLength: 3,
      ajax: {
          dataType: 'json',
          url: "/utils/pessoas",
          processResults: function(data) {
              return {
                  results: data
              };
          }
      }
  });
}

window.add_selectize_without_modal = function() {
  $(".selectize_with_add_without_modal").each(function(i, item) {
    $(item).selectize({
      create: function(input, callback) {
        $(item).parent().removeClass('has-error');
        $(item).parent().find('.help-block').text("");
        var selectizeCallback = callback;
        var data = {};
        data[$(item).data('model')] = {};
        data[$(item).data('model')][$(item).data('field')] = input;
        $.ajax({
          method: "POST",
          dataType: "json",
          url: "/" + $(item).data('pluralized'),
          data: data,
          success: function(response) {
            selectizeCallback({value: response.id, text: response.name});
          },
          error: function(response) {
            $(item).parent().addClass('form-group-invalid');
            if (!( $(item).parent().find('.invalid-feedback.d-block').length)) {
                $(item).parent().append("<div class='invalid-feedback d-block'></div>");
            }
            $(item).parent().find('.invalid-feedback.d-block').text("Valor já existente: " + input);
            selectizeCallback();
          }
        });
        selectizeCallback = callback;
      }
    });
  });
}

window.add_file_input = function() {
  $(".bootstrap_file_input").each(function() {
    var input = $(this);
    input.fileinput({
        language: 'pt-BR',
        // allowedFileExtensions: ['pdf'],
        'previewFileType': 'any',
        initialPreviewAsData: true,
        initialPreview: input.data('url'),
        showPreview: input.data('show_preview'),
        initialPreviewFileType: 'pdf',
        initialPreviewConfig: [
            { caption: input.data('filename'), height: 'auto', widht: '120px', showDelete: false, showDrag: false, key: 1 }
        ],
        showRemove: input.data('show_remove'),
        showClose: true,
        showUpload: false,
        showCancel: false
    });
  });
}

window.add_masks = function() {
    $('.masked_cpf').each(function(){
      $(this).mask("999.999.999-99");
    });

    $('.masked_cnpj').each(function(){
      $(this).mask("99.999.999/9999-99");
    });

    $('.masked_phone').each(function() {
      $(this).mask("(00) 00000-0000");
    });

    $('.masked_cep').each(function() {
      $(this).mask("00000-000");
    });

    $('.masked_integer').each(function() {
      $(this).mask("#");
    });

    $('.masked_cnae').each(function() {
      $(this).mask('0000-0/00');
    });
}

window.add_money_masks = function(element) {
  var sel_element
  if (element) {
    sel_element = element;
  } else {
    sel_element = $('.masked_money')
  }
  sel_element.each(function(){
    $(this).inputmask('currency',{"autoUnmask": true,
            radixPoint:",",
            groupSeparator: ".",
            allowMinus: true,
            prefix: '',
            digits: 2,
            digitsOptional: false,
            rightAlign: false,
            unmaskAsNumber: true
    });
  });
}
