
document.addEventListener("turbolinks:load", () => {
  $('#pessoa_dependentes').on('cocoon:after-insert', function(e, insertedItem) {
    add_masks();
    add_select2(insertedItem.find('.select2'));
    insertedItem.find('.tipo_dependente_selector').on('change', function (e) {
      toggle_descricao_dependente_field($(this));
    });
  });
});
