
document.addEventListener("turbolinks:load", () => {
  $('input[id$=cep]').blur(function() {
      via_cep($(this));
  });
});

window.via_cep = function(input) {
  $.post("/utils/cep", { cep: input.val() }, function(data) {
      if (data) {
          $('input[id$=logradouro]').val(data.data.address);
          $('input[id$=bairro]').val(data.data.neighborhood);
          $('input[id$=complemento]').val(data.data.complement);
          if (data.cidade) {
              var select = $('select[id$=cidade_id]');
              var option = new Option(data.cidade.nome, data.cidade.id, true, true);
              select.append(option).trigger('change');
          }
          $('input[id$=numero]').focus();
      }
  });
}
