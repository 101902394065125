document.addEventListener("turbolinks:load", () => {

  if ($('#informacao_multiplo_vinculo').children().length > 1) {
    $('#btn_add_info_mv').addClass('d-none');
  }

  if ($('#informacao_periodo_apuracao').children().length > 1) {
    $('#btn_add_info_per_apur').addClass('d-none');
  }

  $(".rra_selector").each(function(){
    addOnChangeRraSelector($(this));
    $(this).trigger('change');
  });

  $('#informacao_multiplo_vinculo').on('cocoon:after-insert', function(e, insertedItem) {
    $('#btn_add_info_mv').addClass('d-none');
    add_masks();
    add_select2();
    add_money_masks();
  });

  $('#informacao_multiplo_vinculo').on('cocoon:after-remove', function(e, removedItem) {
    if (removedItem.hasClass('informacao_multiplo_vinculo_fields')){
      $('#btn_add_info_mv').removeClass('d-none');
    }
  });

  $('#demonstrativos_valores').on('cocoon:after-insert', function(e, insertedItem) {

    if ($('#informacao_periodo_apuracao').children().length > 1) {
      $('#btn_add_info_per_apur').addClass('d-none');
    }

    $('#informacao_periodo_apuracao').on('cocoon:after-insert', function(e, insertedItem) {
      $('#btn_add_info_per_apur').addClass('d-none');
    });

    $('#informacao_periodo_apuracao').on('cocoon:after-remove', function(e, removedItem) {
      if (removedItem.hasClass('informacao_periodo_apuracao_fields')){
        $('#btn_add_info_per_apur').removeClass('d-none');
      }
    });

    add_masks();
    add_select2(insertedItem.find('.select2'));
    add_money_masks(insertedItem.find('.masked_money'));
    if (insertedItem.hasClass('demonstrativo_valores_fields')){
      let vinculoSelect = insertedItem.find('.vinculo_remuneracao_selector');
      let vinculos = [];
      let pessoaId = $('#remuneracao_devida_pessoa_id').find('option:selected').val();
      if (pessoaId) {
        $.ajax({
          type: "GET",
          url: '/pessoas/' + pessoaId + '/json_vinculos.json'
        }).done(function(vinculos) {
          vinculoSelect.empty();
          vinculoSelect.select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: vinculos
          }).val(vinculoSelect.attr('val')).trigger('change');
        });
      }
      else{
        vinculoSelect.empty();
        vinculoSelect.val(null).trigger('change');
      }
    }

    $('.remuneracoes').on('cocoon:after-insert', function(e, insertedItem) {
      if (insertedItem.hasClass('remuneracao_fields')){
        let vinculoSelect = insertedItem.find('.vinculo_remuneracao_selector');
        let vinculos = [];
        let pessoaId = $('#remuneracao_devida_pessoa_id').find('option:selected').val();
        if (pessoaId) {
          $.ajax({
            type: "GET",
            url: '/pessoas/' + pessoaId + '/json_vinculos.json'
          }).done(function(vinculos) {
            vinculoSelect.empty();
            vinculoSelect.select2({
              allowClear: true,
              width: '100%',
              language: 'pt-BR',
              placeholder: "Selecione...",
              data: vinculos
            }).val(vinculoSelect.attr('val')).trigger('change');
          });
        }
        else{
          vinculoSelect.empty();
          vinculoSelect.val(null).trigger('change');
        }
      }
    });

    $('#remuneracao_devida_pessoa_id').on('change', function (e) {
      let vinculos = [];
      let pessoaId = $(this).find('option:selected').val();
      if (pessoaId) {
        $.ajax({
          type: "GET",
          url: '/pessoas/' + pessoaId + '/json_vinculos.json'
        }).done(function(vinculos) {
          $(".vinculo_remuneracao_selector").each(function(){
            $(this).empty();
            $(this).select2({
              allowClear: true,
              width: '100%',
              language: 'pt-BR',
              placeholder: "Selecione...",
              data: vinculos
            }).val($(this).attr('val')).trigger('change');
          });
        });
      }
      else{
        $(".vinculo_remuneracao_selector").each(function(){
          $(this).empty();
          $(this).val(null).trigger('change');
        });
      }
    });
  });

  $('.remuneracoes').on('cocoon:after-insert', function(e, insertedItem) {
    if (insertedItem.hasClass('remuneracao_fields')){
      let vinculoSelect = insertedItem.find('.vinculo_remuneracao_selector');
      let vinculos = [];
      let pessoaId = $('#remuneracao_devida_pessoa_id').find('option:selected').val();
      if (pessoaId) {
        $.ajax({
          type: "GET",
          url: '/pessoas/' + pessoaId + '/json_vinculos.json'
        }).done(function(vinculos) {
          vinculoSelect.empty();
          vinculoSelect.select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: vinculos
          }).val(vinculoSelect.attr('val')).trigger('change');
        });
      }
      else{
        vinculoSelect.empty();
        vinculoSelect.val(null).trigger('change');
      }
    }
  });


  $('#remuneracao_devida_pessoa_id').trigger('change');

  $('#replicacao_remuneracao_devida_form_competencia_origem_id').on('change', function (e) {
    load_remuneracoes_por_competencia($(this).find('option:selected').val());
  });

  function load_remuneracoes_por_competencia(competenciaOrigemId){
    let vinculos = [];
    let competenciaId = $('#replicacao_remuneracao_devida_form_competencia_id').val();
    $.ajax({
      type: "GET",
      dataType: 'script',
      url: '/competencias/' + competenciaId + '/remuneracoes_devidas/replicar?competencia_origem_id=' + competenciaOrigemId
    }).done(function(data) {
      $('#replicacao_remuneracao_devida_form_competencia_origem_id').on('change', function (e) {
        load_remuneracoes_por_competencia($(this).find('option:selected').val());
      });
      $("#checkbox-toggle").click(function(){
        $('.checkable').prop('checked', this.checked);
      });
    });
  }

  function addOnChangeRraSelector(rraSelect){
    rraSelect.on('change', function (e) {
      let rra = $(this).find('option:selected').val();
      let row = $(this).closest('.row');
      if (rra=='true'){
        row.find('.rra_fields').show();
      } else {
        row.find('.numero_processo_rra_field').val(null);
        row.find('.meses_rra_field').val(null);
        row.find('.descricao_rra_field').val(null);
        row.find('.tipo_processo_rra_selector').val(null).trigger('change');
        row.find('.rra_fields').hide();
      }
    });
  }

});
