document.addEventListener("turbolinks:load", () => {
  $('.gerar_evento').on('ajax:success', function (event) {
    if (event.detail[0].configuracao.gerar_evento) {
      $(this).find('span').text('Gerar');
      $(this).find('i').removeClass('fa-square');
      $(this).find('i').addClass('fa-check-square');
      $(this).removeClass('btn-default');
      $(this).addClass('btn-success');
    } else {
      $(this).find('span').text('Não gerar');
      $(this).find('i').removeClass('fa-check-square');
      $(this).find('i').addClass('fa-square');
      $(this).removeClass('btn-success');
      $(this).addClass('btn-default');
    }
  });
});
